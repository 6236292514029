import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../components/layout';

import Logo from './../../images/custom/logo.png'

import { Link } from "gatsby"

const Component = () => {
  return (
    <Layout className={`access__denied`}>

        <div className="authincation h-100">
            <div className="container _container-fluid h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <h4 className="text-center mb-4">
                                            <a href="/" className="brand-logo">
                                                <img className="logo-abbr" src={Logo} alt={``} />
                                            </a>
                                        </h4>
                                        <div class="alert alert-danger      text-center text-white" role="alert">
                                            Dostęp zabroniony, skontaktuj się z administratorem systemu!
                                            <br /><br />
                                            <Link to="/user/profile" className="text-white">Przejdź do logowania</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
  )
}

export default Component;